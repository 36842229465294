import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { routesMap } from "src/routes";
import {
  apiService,
  removeEmptyProps,
  useGetBrands,
  useGetUndefinedBrands,
} from "src/services/apiService";
import {
  Table,
  Link,
  AliasLabel,
  BrandSelectDialog,
  ManufacturerSelectDialog,
  BrandCreateDialog,
  ManufacturerLabel,
} from "src/components/common";

import { formattingService } from "src/services/formattingService";

//shared
import { usePageTitle, useRouter } from "src/shared/hooks";
import { Brand, Manufacturer, UndefinedBrand } from "src/shared/api";

const Brands = () => {
  const pageTitle = usePageTitle();

  const router = useRouter();

  const with_logo = router.query.get("with_logo");
  const highlighted = router.query.get("highlighted");

  useEffect(() => {
    pageTitle.set(routesMap.brands.getTitle());
  }, []);

  const requestBrands = useGetBrands({
    variables: { with_logo, highlighted },
  });

  useMemo(
    () => requestBrands.data?.sort((a, b) => (a.name > b.name ? 1 : -1)),
    [requestBrands.data]
  );

  return (
    <>
      <Box sx={{ display: "flex" }} justifyContent="space-between">
        <Box sx={{ display: "flex" }}>
          <FormControlLabel
            label="Только с логотипом"
            control={<Checkbox checked={Boolean(with_logo)} />}
            onChange={() =>
              router.push(
                `${routesMap.brands.getRoute()}?${new URLSearchParams(
                  //@ts-ignore
                  removeEmptyProps({
                    ...Object.fromEntries(router.query),
                    with_logo: with_logo ? null : true,
                  })
                )}`
              )
            }
          />
          <FormControlLabel
            label="Выделенные"
            control={<Checkbox checked={Boolean(highlighted)} />}
            onChange={() =>
              router.push(
                `${routesMap.brands.getRoute()}?${new URLSearchParams(
                  //@ts-ignore
                  removeEmptyProps({
                    ...Object.fromEntries(router.query),
                    highlighted: highlighted ? null : true,
                  })
                )}`
              )
            }
          />
        </Box>
        <BrandCreateDialog onResponse={requestBrands.refetch} />
      </Box>
      <Paper sx={{ mt: 2 }}>
        <Table
          withPagination={false}
          loading={requestBrands.isLoading}
          totalCount={requestBrands.data?.length || 0}
          data={requestBrands.data || []}
          limit={requestBrands.data?.length || 0}
          offset={requestBrands.data?.length || 0}
          headers={[
            {
              title: "Название",
              extract: (item: Brand) => (
                <Link href={routesMap.brand.getRoute(item.id)} blank>
                  {item.name || "Бренд без названия"}
                </Link>
              ),
            },
            {
              title: "Адаптированое",
              extract: (item: Brand) => item.adapted_name,
            },
            {
              title: "Алиасы",
              extract: (item: Brand) => (
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {item?.aliases?.map((alias) => (
                    <Box sx={{ mr: 1, mt: 1 }} key={alias.id}>
                      <AliasLabel alias={alias} />
                    </Box>
                  ))}
                </Box>
              ),
            },
            {
              title: "Производитель",
              extract: (item: Brand) =>
                item?.manufacturer && (
                  <ManufacturerLabel
                    manufacturer={item?.manufacturer}
                    linkIcon
                  />
                ),
            },
          ]}
        />
      </Paper>
    </>
  );
};
const Undefined = () => {
  const [loading, setLoading] = useState(false);

  const requestUbdefinedBrands = useGetUndefinedBrands();

  const brands = useMemo(
    () =>
      requestUbdefinedBrands.data?.sort((a, b) => (a.value > b.value ? 1 : -1)),
    [requestUbdefinedBrands.data]
  );

  const handleRefetch = () => {
    requestUbdefinedBrands.refetch();
    setLoading(false);
  };

  const handleAddAliasToBrand = (
    alias: UndefinedBrand,
    brand: Brand | null
  ) => {
    if (brand) {
      setLoading(true);
      apiService
        .addAliasToBrand({
          brandId: brand.id,
          alias,
        })
        .then(handleRefetch);
    }
  };

  const handleCreateBrand = (
    manufacturer: Manufacturer,
    item: UndefinedBrand
  ) => {
    setLoading(true);
    apiService
      .createBrand({ name: item.value, manufacturer: manufacturer.id })
      .then(handleRefetch);
  };

  return (
    <Paper>
      <Table
        withPagination={false}
        loading={requestUbdefinedBrands.isLoading}
        totalCount={brands?.length || 0}
        data={brands || []}
        limit={brands?.length || 0}
        offset={brands?.length || 0}
        headers={[
          {
            title: "Название",
            extract: (item: UndefinedBrand) => item.value,
          },
          {
            title: "Источник",
            extract: (item: UndefinedBrand) => (
              <div>
                <div>{item.supplier.name}</div>
                <Typography variant="caption">
                  {formattingService.date(item.createTime)}
                </Typography>
              </div>
            ),
          },
          {
            title: "Действия",
            extract: (item: UndefinedBrand) => (
              <Box display="flex">
                <BrandSelectDialog
                  title={`Добавление алиаса "${item.value}" к бренду`}
                  onSave={(manufacturer) =>
                    handleAddAliasToBrand(item, manufacturer)
                  }
                >
                  <Button size="small" variant="contained" disabled={loading}>
                    Добавить алиас к бренду
                  </Button>
                </BrandSelectDialog>
                <ManufacturerSelectDialog
                  title={`Добавить бренд ${item.value} к производителю`}
                  onSave={(manufacturer) =>
                    handleCreateBrand(manufacturer, item)
                  }
                >
                  <Button
                    disabled={loading}
                    size="small"
                    sx={{ ml: 1 }}
                    variant="contained"
                    color="secondary"
                  >
                    Создать новый бренд
                  </Button>
                </ManufacturerSelectDialog>
              </Box>
            ),
          },
        ]}
      />
    </Paper>
  );
};

interface Props {}

export const BrandsPage: React.FunctionComponent<Props> = ({}) => {
  const router = useRouter();

  const tab = router.query.get("tab") || "brands";

  return (
    <div>
      <Tabs
        value={tab}
        onChange={(e, value) =>
          router.push(
            `${routesMap.brands.getRoute()}?${new URLSearchParams({
              tab: value,
            }).toString()}`
          )
        }
      >
        <Tab value="brands" label="Бренды" />
        <Tab value="undefined" label="Новые" />
      </Tabs>
      <Box mt={2}>
        {tab === "brands" && <Brands />}
        {tab === "undefined" && <Undefined />}
      </Box>
    </div>
  );
};
